import { FC, useEffect, useRef, useState } from 'react';
import { FormErrors } from '../../constants/errorMessages';
import { AlertSVG, DropDownSVG } from '../svgs';

export type FilterOption<T> = {
  name: string;
  value: T;
  optionDisabled?: boolean;
  optionWarning?: boolean;
};

export const defaultFilterOption = { name: '', value: '' };
export const defaultFilterOptionBoolean = { name: '', value: false };

type FilterDropdownProps<T> = {
  id?: string;
  options: FilterOption<T>[];
  value: FilterOption<T>;
  onChange: (e: FilterOption<T>) => void;
  validate?: boolean;
  placeholder?: string;
  searchPlaceholder?: string;
  disabled?: boolean;
};

const FilterDropdown: FC<FilterDropdownProps<any>> = ({
  id,
  options,
  value,
  onChange: setValue,
  validate = false,
  searchPlaceholder = 'Search...',
  placeholder = 'Choose One',
  disabled = false,
}: FilterDropdownProps<any>) => {
  const [input, setInput] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [highlighted, setHighlighted] = useState<number>(0);
  const [filteredOptions, setFilteredOptions] = useState<FilterOption<any>[]>([]);
  const scrollRef = useRef<HTMLDivElement>(null);

  const [hidden, setHidden] = useState<boolean>(true);

  const focus = async () => {
    updateInput('');
    setHighlighted(-1);
    setHidden(false);
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  };

  const selectItem = (option: FilterOption<any>): void => {
    setValue({ name: option.name, value: option.value });
  };

  const getFilteredOptions = (): FilterOption<any>[] => {
    return options.filter(item => {
      return item.name.toLowerCase().includes(input.toLowerCase());
    });
  };

  const updateInput = (inputString: string) => {
    setInput(inputString);
    setFilteredOptions(
      options.filter(item => {
        return item.name.toLowerCase().includes(inputString.toLowerCase());
      }),
    );
  };

  const onMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: FilterOption<any>) => {
    event.preventDefault();
    event.stopPropagation();

    selectItem(item);
    if (inputRef.current !== null) {
      setInput('');
      inputRef.current.value = '';
    }
    setHidden(true);
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!hidden) {
      const filteredOptionsArray: FilterOption<any>[] = getFilteredOptions();
      switch (e.key) {
        case 'ArrowDown':
          const next: number = Math.min(highlighted + 1, filteredOptionsArray.length - 1);
          !filteredOptionsArray[next].optionDisabled
            ? selectItem(filteredOptionsArray[next])
            : selectItem({ name: '', value: '' });
          setHighlighted(next);
          break;
        case 'ArrowUp':
          const previous: number = Math.max(highlighted - 1, 0);
          setHighlighted(previous);
          !filteredOptionsArray[previous].optionDisabled
            ? selectItem(filteredOptionsArray[previous])
            : selectItem({ name: '', value: '' });
          break;
        case 'Enter':
          setHidden(true);
          if (highlighted === -1) {
            !filteredOptionsArray[0].optionDisabled && selectItem(filteredOptionsArray[0]);
          }
          break;
        default:
          setHighlighted(-1);
          break;
      }
    }
  };

  useEffect(
    function scrollOptionsToTop() {
      if (scrollRef.current) scrollRef.current.scrollTop = 0;
    },
    [hidden],
  );

  const formatLabel = (label: string): JSX.Element => {
    return label?.includes('	') ? (
      <p className="flex-apart">
        {label.split('	').map((text, i) => {
          return <span key={'dropdown-item-key-' + text + '-i:' + i}>{text}</span>;
        })}
      </p>
    ) : (
      <p>{label}</p>
    );
  };

  return (
    <>
      <div
        className={disabled ? 'filter-dropdown disabled' : 'filter-dropdown'}
        onClick={() => {
          if (!disabled) {
            focus();
          }
        }}>
        <div className="form-field">
          {value.name !== '' ? formatLabel(value.name) : placeholder}
          <DropDownSVG />
        </div>

        {!disabled && (
          <div className={hidden ? 'options hidden' : 'options'} ref={scrollRef}>
            <input
              className="filter-input"
              type="text"
              ref={inputRef}
              placeholder={searchPlaceholder}
              value={input}
              onChange={e => updateInput(e.target.value)}
              onFocus={focus}
              onBlur={() => setHidden(true)}
              onKeyDownCapture={e => handleKeypress(e)}
            />

            {filteredOptions.length > 0 ? (
              filteredOptions.map((item, index) => {
                return (
                  <div
                    className={`${index === highlighted ? 'option highlighted' : 'option'} ${
                      item.optionDisabled ? 'option-disabled' : item.optionWarning ? 'option-warning' : ''
                    }`}
                    onMouseDown={event => onMouseDown(event, item)}
                    key={'dropdownValue:' + item.value + '-index:' + index}>
                    {item.optionDisabled || item.optionWarning ? (
                      <>{formatLabel(item.name)} (Due Payment)</>
                    ) : (
                      formatLabel(item.name)
                    )}
                  </div>
                );
              })
            ) : (
              <div className="no-results">No options match "{input}"</div>
            )}
          </div>
        )}
      </div>
      {validate && !value.value && (
        <div className="validated-input-message-error">
          <AlertSVG /> {FormErrors[0].message}
        </div>
      )}
    </>
  );
};

export default FilterDropdown;
