import { FC, useEffect, useState } from 'react';
import Loader from '../loader/Loader';
import { Specimen, SpecimenLocation } from '../../types/interfaces/specimen.interfaces';
import { showToast } from '../../services/toast.service';
import { formatPositiveNumber } from '../../utils/commonUtils';
import { getSpecimenLocations } from '../../api/specimensApi';
import { toastMessages } from '../../constants/errorMessages';

export type CanisterQuantity = {
  specimenLocation: SpecimenLocation;
  oldQty: number;
  actualQty: number;
};

type ReconcileCanisterSpecimensProps = {
  specimen: Specimen;
  onChange: (e: { value: CanisterQuantity[]; valid: boolean }) => void;
};

const ReconcileCanisterSpecimens: FC<ReconcileCanisterSpecimensProps> = ({
  specimen,
  onChange,
}: ReconcileCanisterSpecimensProps): JSX.Element => {
  const [specimens, setSpecimens] = useState<CanisterQuantity[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data: specimenLocations } = await getSpecimenLocations({
          include: 'StorageCanister.StorageTank.StorageSite',
          filter: `specimenId eq ${specimen.specimenId}`,
        });
        setSpecimens(
          specimenLocations.map((spec: SpecimenLocation) => {
            return { specimenLocation: spec, oldQty: spec.quantity, actualQty: spec.quantity };
          }),
        );
      } catch {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setLoading(false);
      }
    };

    if (specimen) {
      fetch();
    }
  }, [specimen]);

  useEffect(() => {
    onChange({ value: specimens, valid: !loading });
  }, [specimens]);

  const getStorageTotal = (): number => {
    return specimens.reduce((acc: number, spec: CanisterQuantity): number => acc + spec.actualQty, 0);
  };

  const onActualQtyChange = (e: any, index: number) => {
    const count = formatPositiveNumber(e.target.value);
    let newSpecimens = [...specimens];
    newSpecimens[index].actualQty = count;
    setSpecimens(newSpecimens);
  };

  return loading ? (
    <Loader loaderSize="small" addedSpace />
  ) : (
    <>
      <div className="desk-reconcile-canister-specimens">
        <div className="specimen-table">
          <table>
            <thead>
              <tr>
                <th>Location</th>
                <th>Item</th>
                <th>Qty In Canister</th>
                <th>Actual Qty</th>
              </tr>
            </thead>
            <tbody>
              {specimens?.map((spec: CanisterQuantity, index: number) => {
                return (
                  <tr key={'canister-specimen-row:' + spec.specimenLocation.specimenId + '-index:' + index}>
                    <td>
                      {spec.specimenLocation?.storageCanister?.storageTank?.storageSite?.name}/
                      {spec.specimenLocation?.storageCanister?.storageTank?.name}/{spec.specimenLocation?.storageCanister?.name}
                    </td>
                    <td>
                      {specimen.animal?.code} - {new Date(specimen.freezeDate!).toLocaleDateString()}
                    </td>
                    <td>{spec.oldQty}</td>
                    <td>
                      <input type="text" placeholder="0" value={spec.actualQty} onChange={e => onActualQtyChange(e, index)} />
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan={3} />
                <td>
                  <div className="storage-total-quantity quantity">{getStorageTotal().toString()}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/** Mobile View */}
      <div className="xs-reconcile-canister-specimens">
        {specimens.length > 0 &&
          specimens &&
          specimens.map((spec, index) => {
            return (
              <div className="card max-width" key={spec?.specimenLocation?.specimenId + '-index:' + index}>
                <div className="reconcile-canister-specimens">
                  <div className="card-content-section">
                    <div className="left center column">
                      <label>{'Location: '}</label>
                      <label>{`${spec.specimenLocation?.storageCanister?.storageTank?.storageSite?.name} /  ${spec.specimenLocation?.storageCanister?.storageTank?.name} / ${spec.specimenLocation?.storageCanister?.name} `}</label>
                    </div>
                    <div className="right center column">
                      <label>{'Item: '}</label>
                      <label>{`${specimen.animal?.code} - ${new Date(specimen.freezeDate!).toLocaleDateString()}`}</label>
                    </div>
                  </div>
                  <div className="card-content-section">
                    <div className="left center">
                      <label>{`Qty In Canister: ${spec.oldQty}`}</label>
                    </div>
                    <div className="right center column">
                      <label>{`Actual Qty: `}</label>
                      <input type="text" placeholder="0" value={spec.actualQty} onChange={e => onActualQtyChange(e, index)} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        <div className="total-quantity">
          <label>Actual Qty: &nbsp;</label>
          {getStorageTotal().toString()}
        </div>
      </div>
    </>
  );
};

export default ReconcileCanisterSpecimens;
