import { FC, useEffect, useState } from 'react';
import Loader from '../loader/Loader';
import { Specimen } from '../../types/interfaces/specimen.interfaces';
import { InventoryTransaction } from '../../types/interfaces';
import { showToast } from '../../services/toast.service';
import { formatPositiveNumber } from '../../utils/commonUtils';
import { getOriginationTransactions } from '../../api/transactionsApi';
import { toastMessages } from '../../constants/errorMessages';
import { TABLE_HEADER_CONSTANTS } from '../../constants/common';

export type OriginationQuantity = {
  transaction: InventoryTransaction;
  ownershipPercentage?: number;
  oldQty: number;
  actualQty: number;
};

type ReconcileOriginationProps = {
  specimen: Specimen;
  onChange: (e: { value: OriginationQuantity[]; valid: boolean }) => void;
};

const ReconcileOrigination: FC<ReconcileOriginationProps> = ({ specimen, onChange }: ReconcileOriginationProps): JSX.Element => {
  const [specimens, setSpecimens] = useState<OriginationQuantity[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data: originTransactions } = await getOriginationTransactions(specimen.specimenId);
        const specimenOwnership = originTransactions.map((trans: InventoryTransaction) => {
          const quant = trans?.changeQuantity!;
          const perc = specimen.animal?.owners?.find(i => i.accountId === trans.accountId)?.ownershipPercentage;
          return { transaction: trans, ownershipPercentage: perc, oldQty: quant, actualQty: quant };
        });
        setSpecimens(specimenOwnership);
      } catch {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setLoading(false);
      }
    };

    if (specimen) {
      fetch();
    }
  }, [specimen]);

  useEffect(() => {
    onChange({ value: specimens, valid: !loading });
  }, [specimens]);

  const getTotal = (): number => {
    return specimens.reduce((acc: number, spec: OriginationQuantity): number => acc + spec.actualQty, 0);
  };

  const onAdjustedQtyChange = (e: any, index: number) => {
    const count = formatPositiveNumber(e.target.value);
    let newSpecimens = [...specimens];
    newSpecimens[index].actualQty = count;
    setSpecimens(newSpecimens);
    onChange({ value: newSpecimens, valid: true });
  };

  return loading ? (
    <Loader loaderSize="small" addedSpace />
  ) : (
    <>
      <div className="desk-reconcile-origination">
        <div className="specimen-table">
          <table>
            <thead>
              <tr>
                <th>{TABLE_HEADER_CONSTANTS.TYPE}</th>
                <th>{TABLE_HEADER_CONSTANTS.ACCOUNT_OWNER}</th>
                <th>{TABLE_HEADER_CONSTANTS.ANIMAL_OWNERSHIP_PER}</th>
                <th>{TABLE_HEADER_CONSTANTS.ORIGINAL_QTY}</th>
                <th>{TABLE_HEADER_CONSTANTS.ADJUSTED_QTY}</th>
              </tr>
            </thead>
            <tbody>
              {specimens?.map((trans: OriginationQuantity, index: number) => {
                return (
                  <tr key={'canister-specimen-row:' + trans.transaction.inventoryTransactionId + '-index:' + index}>
                    <td>{trans.transaction.transactionType}</td>
                    <td>{trans.transaction.account?.name}</td>
                    <td>{trans.ownershipPercentage}</td>
                    <td>{trans.oldQty}</td>
                    <td>
                      <input type="text" placeholder="0" value={trans.actualQty} onChange={e => onAdjustedQtyChange(e, index)} />
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan={4} />
                <td>
                  <div className="original-quantity quantity">{getTotal().toString()}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/** Mobile View */}
      <div className="xs-reconcile-origination">
        {specimens.length > 0 &&
          specimens &&
          specimens.map((trans, index) => {
            return (
              <div className="card max-width" key={trans?.transaction?.transactionLinkId! + index}>
                <div className="reconcile-origination">
                  <div className="card-content-section">
                    <div className="left center">
                      <label>{`Type: ${trans.transaction.transactionType}`}</label>
                    </div>
                    <div className="right column center">
                      <label>{'Account/Owner: '}</label>
                      <label>{`${trans.transaction.account?.name}`}</label>
                    </div>
                  </div>
                  <div className="card-content-section">
                    <div className="left center column">
                      <label>{'Animal Ownership%: '}</label>
                      <label>{`${trans?.ownershipPercentage}`}</label>
                    </div>
                    <div className="left center">
                      <label>{'Original Qty: '}</label>
                      <label>{`${trans?.oldQty}`}</label>
                    </div>
                    <div className="right center column">
                      <label>{`Adjusted Qty:`}</label>
                      <input type="text" placeholder="0" value={trans.actualQty} onChange={e => onAdjustedQtyChange(e, index)} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ReconcileOrigination;
