import { AxiosResponse } from 'axios';
import { appContextGet, appContextPut } from './httpCommon/appAxios.service';
import {
  Specimen,
  SpecimenInventoryWithAccount,
  SpecimenInventoryWithUseRequested,
  SpecimenVarianceSummary,
} from '../types/interfaces/specimen.interfaces';
import { SpecimenInventory, SpecimenLocation, SpecimenWithOwnership } from '../types/interfaces/specimen.interfaces';
import {
  GetAccountAnimalParams,
  GetVariancesResponse,
  SpecimensInventoryParams,
  StandardParams,
} from '../types/interfaces/apiParams.interfaces';
import { Account } from '../types/interfaces';
import { SPECIMENS_API_ENDPOINTS, SPECIMENS_LOCATIONS_API_ENDPOINTS } from '../constants/apiEndpoints';

export const getInventoryVarianceSummary = (specimenId: number): Promise<AxiosResponse<SpecimenVarianceSummary, any>> => {
  return appContextGet(SPECIMENS_API_ENDPOINTS.GET_VARIANCE_SUMMARY + specimenId.toString());
};

export const getQuantityInCanister = (
  canisterId: number | string,
  accountId: number | string,
  specimenId: number | string,
): Promise<AxiosResponse<SpecimenInventory[], any>> => {
  return appContextGet(
    SPECIMENS_API_ENDPOINTS.SPECIMENS +
      specimenId +
      SPECIMENS_API_ENDPOINTS.ACCOUNTS +
      accountId +
      SPECIMENS_API_ENDPOINTS.CANISTERS +
      canisterId,
  );
};

export const getRemovedCount = (specimenId: number): Promise<AxiosResponse<number, any>> => {
  return appContextGet(SPECIMENS_API_ENDPOINTS.SPECIMENS + specimenId.toString() + SPECIMENS_API_ENDPOINTS.REMOVED_COUNT);
};

export const getSpecimen = (id: number, params?: { include: string }): Promise<AxiosResponse<Specimen, any>> => {
  return appContextGet(SPECIMENS_API_ENDPOINTS.GET + '/' + id, params);
};

export const getSpecimenLocations = (params?: any): Promise<AxiosResponse<SpecimenLocation[], any>> => {
  return appContextGet(SPECIMENS_LOCATIONS_API_ENDPOINTS.GET, params);
};

export const getSpecimenOwnerByAccountId = (
  specimenId: number | string,
  accountId: number | string,
): Promise<AxiosResponse<SpecimenInventoryWithAccount[], any>> => {
  return appContextGet(SPECIMENS_API_ENDPOINTS.SPECIMENS + specimenId + SPECIMENS_API_ENDPOINTS.ACCOUNTS + accountId);
};

export const getSpecimenOwners = (specimenId: number | string): Promise<AxiosResponse<Account[], any>> => {
  return appContextGet(SPECIMENS_API_ENDPOINTS.SPECIMENS + specimenId + SPECIMENS_API_ENDPOINTS.ACCOUNTS);
};

export const getSpecimens = (params?: StandardParams): Promise<AxiosResponse<Specimen[], any>> => {
  return appContextGet(SPECIMENS_API_ENDPOINTS.GET, params);
};

export const getSpecimensByAccount = (
  accountId: number,
  params?: GetAccountAnimalParams,
): Promise<AxiosResponse<SpecimenInventoryWithAccount[], any>> => {
  return appContextGet(SPECIMENS_API_ENDPOINTS.GET_ACCOUNT_INVENTORY + accountId, params);
};

export const getSpecimensByAnimal = (params?: {}): Promise<AxiosResponse<Specimen, any>> => {
  return appContextGet(SPECIMENS_API_ENDPOINTS.GET, params);
};

export const getSpecimensByOwnership = (
  animalId: number | string,
  accountId: number | string,
  params?: {},
): Promise<AxiosResponse<SpecimenInventory[], any>> => {
  return appContextGet(SPECIMENS_API_ENDPOINTS.GET_ACCOUNTS + accountId + SPECIMENS_API_ENDPOINTS.ANIMALS + animalId, params);
};

export const getSpecimensInventory = (params?: SpecimensInventoryParams): Promise<AxiosResponse<Specimen[], any>> => {
  return appContextGet(SPECIMENS_API_ENDPOINTS.GET_INVENTORY, params);
};

export const getSpecimensInventoryByCanisterId = (canisterId: number | string): Promise<AxiosResponse<Specimen[], any>> => {
  return appContextGet(SPECIMENS_API_ENDPOINTS.GET + SPECIMENS_API_ENDPOINTS.CANISTERS + canisterId);
};

export const getUsePendingSpecimensByAccountId = (
  accountId: number | string,
  params?: StandardParams,
): Promise<AxiosResponse<SpecimenInventoryWithUseRequested[], any>> => {
  return appContextGet(SPECIMENS_API_ENDPOINTS.GET_ACCOUNTS + accountId + SPECIMENS_API_ENDPOINTS.USE_PENDING, params);
};

export const getUsePendingSpecimensByCanisterId = (
  canisterId: number | string,
  params?: StandardParams,
): Promise<AxiosResponse<SpecimenWithOwnership[], any>> => {
  return appContextGet(SPECIMENS_API_ENDPOINTS.GET_USE_PENDING + '/' + canisterId, params);
};

export const getVariance = (specimenId: number): Promise<AxiosResponse<number, any>> => {
  return appContextGet(SPECIMENS_API_ENDPOINTS.GET_VARIANCE_ + specimenId.toString());
};

export const getVariances = (params?: {}): Promise<AxiosResponse<GetVariancesResponse[], any>> => {
  return appContextGet(SPECIMENS_API_ENDPOINTS.GET_VARIANCES, params);
};

export const updateSpecimen = (id: number, payload: {}): Promise<AxiosResponse<{}, any>> => {
  return appContextPut(SPECIMENS_API_ENDPOINTS.UPDATE + id, payload);
};
