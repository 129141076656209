import { useState } from 'react';
import { FilterDropdown, Loader, Modal } from '../../components';
import { FilterOption } from '../../components/custom-input/FilterDropdown';
import { EditSVG, PlusCircleSvg } from '../svgs';
import './managementColumns.scss';

export enum ManagementColumnFilterTypes {
  ACTIVE = 'active eq true;',
  INACTIVE = 'active neq true;',
  BOTH = '',
}

interface ManagementColumnsProps {
  header: string;
  filter?: FilterOption<ManagementColumnFilterTypes>;
  setFilter?: (filter: FilterOption<ManagementColumnFilterTypes>) => void;
  headerStats: { value?: string | number; label: string }[];
  columns: ManagementColumn<any>[];
}

interface ManagementColumn<T> {
  header: string;
  items: T[];
  selectedItemId?: number;
  getItemId: (item: T) => number;
  addItemButton: { label: string; disabled?: boolean };
  getItemLabel: (item: T) => JSX.Element;
  getItemAddModal: (close: () => void) => JSX.Element;
  getItemEditModal: (close: () => void, item: T) => JSX.Element;
  itemSelect: (item: T) => void;
  scroll?: () => void;
  scrollRef?: any;
  loading?: boolean;
}

const ManagementColumns = ({ header, filter, setFilter, headerStats, columns }: ManagementColumnsProps): JSX.Element => {
  const [modal, setModal] = useState<JSX.Element>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleScroll = (e: any, col: ManagementColumn<any>) => {
    const scrollGap = 50;
    const position = e.target.scrollHeight - e.target.scrollTop;
    const atBottom = position - e.target.clientHeight <= scrollGap;
    if (atBottom && col.scroll) {
      col.scroll();
    }
  };

  return (
    <div className="management-columns">
      {isOpen && (
        <Modal isOpen={isOpen} onClose={closeModal}>
          <div className="management-columns-modal">{modal}</div>
        </Modal>
      )}
      <div className="header">
        <div className="header-section header-stats">
          <h1>{header}</h1>
          {headerStats.map((stat, index) => {
            return (
              <div key={'header-stat-' + stat + '-' + index}>
                <span>
                  <label>{stat.value ?? '--'}</label> &nbsp;{stat.label}
                </span>
              </div>
            );
          })}
        </div>
        {setFilter && filter && (
          <div className="header-section">
            <div className="show-label">Show:&emsp;</div>
            <div className="show-status">
              <FilterDropdown
                options={[
                  { name: 'Active', value: ManagementColumnFilterTypes.ACTIVE },
                  { name: 'Inactive', value: ManagementColumnFilterTypes.INACTIVE },
                  { name: 'Active & Inactive', value: ManagementColumnFilterTypes.BOTH },
                ]}
                value={filter}
                onChange={filterOption => setFilter(filterOption)}
              />
            </div>
          </div>
        )}
      </div>
      <div className="content">
        {columns.map((col: ManagementColumn<any>, colIndex: number) => {
          return (
            <div key={'management-column-' + colIndex} className="content-section">
              <div className="content-header">
                <h2>{col.header}</h2>
                <button
                  className="medium green icon button"
                  onClick={() => {
                    setIsOpen(true);
                    setModal(col.getItemAddModal(closeModal));
                  }}
                  disabled={col.addItemButton.disabled}>
                  <PlusCircleSvg />
                  &nbsp;Add&nbsp;{col.addItemButton.label}
                </button>
              </div>
              <div
                className="content-content"
                onScroll={e => {
                  if (col.scroll && col.items.length > 0) {
                    handleScroll(e, col);
                  }
                }}>
                {col.items.map((item, itemIndex) => {
                  return (
                    <div
                      key={'management-column-item-' + colIndex + '-' + itemIndex}
                      className={col.getItemId(item) === col.selectedItemId ? 'item selected' : 'item'}
                      onClick={() => col.itemSelect(item)}>
                      <div>
                        <button
                          className="icon button transaprent"
                          onClick={() => {
                            setIsOpen(true);
                            setModal(col.getItemEditModal(closeModal, item));
                          }}>
                          <EditSVG />
                        </button>
                        &nbsp;
                        <div className="management-column-label">{col.getItemLabel(item)}</div>
                      </div>
                    </div>
                  );
                })}
                {col.loading && <Loader loaderSize="small" addedSpace />}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ManagementColumns;
