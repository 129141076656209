import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { BackButton } from '../../components';
import ReconcileCanisterSpecimens, { CanisterQuantity } from '../../components/specimen-tables/ReconcileCanisterSpecimens';
import ReconcileAccountOwnership, { AccountOwnershipQuantity } from '../../components/specimen-tables/ReconcileAccountOwnership';
import ReconcileOrigination, { OriginationQuantity } from '../../components/specimen-tables/ReconcileOrigination';
import { AlertSVG } from '../../components/svgs';
import { ReconcileAdjustInventoryBody, Specimen } from '../../types/interfaces';
import { showToast } from '../../services/toast.service';
import { getRemovedCount, getSpecimen } from '../../api/specimensApi';
import { reconcileAdjustInventory } from '../../api/inventoryApi';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import {
  BUTTON_CONSTANTS,
  LABEL_CONSTANTS,
  PAGE_HEADER_CONSTANTS,
  TABLE_HEADER_CONSTANTS,
  VALIDATION_ALERT_CONSTANTS,
} from '../../constants/common';

const InventoryManagementReconcileItem = (): JSX.Element => {
  const { specimenId } = useParams();
  const navigate = useNavigate();

  const [specimen, setSpecimen] = useState<Specimen>();
  const [originationQuantities, setOriginationQuantities] = useState<{ value: OriginationQuantity[]; valid: boolean }>({
    valid: false,
    value: [],
  });
  const [accountQuantities, setAccountQuantities] = useState<{ value: AccountOwnershipQuantity[]; valid: boolean }>({
    valid: false,
    value: [],
  });
  const [locationQuantities, setLocationQuantities] = useState<{ value: CanisterQuantity[]; valid: boolean }>({
    valid: false,
    value: [],
  });
  const [removedQuantity, setRemovedQuantity] = useState<number>(0);
  const [notes, setNotes] = useState<string>('');
  const [updatingInventory, setUpdatingInventory] = useState<boolean>(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        const [{ data: specimenInfo }, { data: removedCount }] = await Promise.all([
          getSpecimen(+specimenId!, { include: 'Animal.Owners' }),
          getRemovedCount(+specimenId!),
        ]);

        setSpecimen(specimenInfo);
        setRemovedQuantity(removedCount);
      } catch {
        showToast.error(toastMessages.FAILED_TO_RETRIEVE_SPECIMEN);
      }
    };
    fetch();
    window.scroll(0, 0);
  }, []);

  const getVariance = (): number => {
    const actualQty = getStorageTotal();
    const ownedQty = getOwnedTotal();
    const expectedQty = getOriginationTotal() - removedQuantity;

    const calculatedVariance =
      originationQuantities.valid && accountQuantities.valid && locationQuantities.valid
        ? Math.abs(expectedQty - actualQty) + Math.abs(Math.abs(ownedQty - actualQty) - Math.abs(expectedQty - actualQty))
        : 0;
    return calculatedVariance;
  };

  const getOwnedTotal = () => {
    return accountQuantities?.value.reduce((acc: number, spec: AccountOwnershipQuantity): number => acc + spec.actualQty, 0);
  };

  const getStorageTotal = () => {
    return locationQuantities?.value.reduce((acc: number, spec: CanisterQuantity): number => acc + spec.actualQty, 0);
  };

  const getOriginationTotal = () => {
    return originationQuantities?.value.reduce((acc: number, spec: OriginationQuantity): number => acc + spec.actualQty, 0);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!updatingInventory) return;
    setUpdatingInventory(false);

    if (getVariance() === 0) {
      try {
        const origQunats = originationQuantities?.value
          .filter((q: OriginationQuantity) => {
            return q.actualQty !== q.oldQty;
          })
          .map((q: OriginationQuantity) => {
            return { inventoryTransactionId: q.transaction?.inventoryTransactionId!, newQuantity: q.actualQty };
          });

        const accountOriginChanges = originationQuantities?.value
          .filter((q: OriginationQuantity) => {
            return q.actualQty !== q.oldQty;
          })
          .map((q: OriginationQuantity) => {
            return { accountId: q.transaction.accountId, changedQuantity: q.actualQty - q.oldQty };
          });

        let accQuants = accountQuantities?.value
          .filter((q: AccountOwnershipQuantity) => {
            return q.actualQty !== q.oldQty;
          })
          .map((q: AccountOwnershipQuantity) => {
            return { accountId: q.account.accountId!, changedQuantity: q.actualQty - q.oldQty };
          });

        accountOriginChanges.forEach(q => {
          const index = accQuants.findIndex(i => i.accountId === q.accountId);
          if (index > -1) {
            accQuants[index] = {
              accountId: accQuants[index].accountId,
              changedQuantity: accQuants[index].changedQuantity - q.changedQuantity,
            };
          } else {
            accQuants.push({ accountId: q.accountId, changedQuantity: 0 - q.changedQuantity });
          }
        });

        accQuants = accQuants.filter((q: { accountId: number; changedQuantity: number }) => {
          return q.changedQuantity !== 0;
        });

        const locQuants = locationQuantities?.value
          .filter((q: CanisterQuantity) => {
            return q.actualQty !== q.oldQty;
          })
          .map((q: CanisterQuantity) => {
            return { storageCanisterId: q.specimenLocation.storageCanisterId, newQuantity: q.actualQty };
          });

        const body: ReconcileAdjustInventoryBody = {
          specimenId: +specimenId!,
          originationChanges: origQunats,
          ownershipChanges: accQuants,
          locationChanges: locQuants,
          notes: notes,
        };
        await reconcileAdjustInventory(body);
        showToast.success(toastMessages.SUCCESS);
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setUpdatingInventory(true);
        navigate(ROUTE_PATHS.APP_INVENTORY_MANAGEMENT_RECONCILE);
      }
    } else {
      showToast.warning('Variance must be 0. Review Inventory Integrity for details.');
    }
  };

  return (
    <>
      <BackButton />
      <div className="inventory-management inventory-management-reconcile inventory-action card">
        <form onSubmit={handleSubmit}>
          <h1>{PAGE_HEADER_CONSTANTS.RECONCILE_INVENTORY_OWNERSHIP}</h1>
          <div className="specimen-info-header">
            <div>
              <div className="specimen-info">
                <label>{LABEL_CONSTANTS.LOT_DATE_NUMBER}:</label>
                <span>
                  &nbsp;{new Date(specimen?.freezeDate!).toLocaleDateString()} - {specimen?.specimenId}
                </span>
              </div>
              <div className="specimen-info">
                <label>{LABEL_CONSTANTS.ORIGINAL_LOT_QTY}:</label>
                <span>&nbsp;{specimen?.originalQuantity}</span>
              </div>
              <div className="specimen-info">
                <label>{LABEL_CONSTANTS.ANIMAL_INFO}:</label>
                <span>
                  &nbsp;{specimen?.animal?.name} - {specimen?.animal?.code}
                </span>
              </div>
            </div>
            <div className="variance">
              <label>{LABEL_CONSTANTS.VARIANCE}</label>
              <div>{getVariance()}</div>
            </div>
          </div>

          <h2>{LABEL_CONSTANTS.ORIGINATION}</h2>
          <ReconcileOrigination specimen={specimen!} onChange={setOriginationQuantities} />

          <h2>{LABEL_CONSTANTS.ACCOUNT_OWNERSHIP}</h2>
          <ReconcileAccountOwnership specimen={specimen!} onChange={setAccountQuantities} />

          <h2>{LABEL_CONSTANTS.ITEM_STORAGE_LOCATIONS}</h2>
          <ReconcileCanisterSpecimens specimen={specimen!} onChange={setLocationQuantities} />
          <br />

          <div className="form-row expanded-text-area">
            <label htmlFor="notes">{LABEL_CONSTANTS.NOTES}:</label>
            <textarea
              id="notes"
              name="notes"
              placeholder="Notes"
              value={notes}
              onChange={e => setNotes(e.target.value)}
              rows={4}
              cols={50}
            />
          </div>

          <br />
          <div className="flex-right">
            <button type="submit" className={getVariance() === 0 ? 'small green button' : 'small green button disabled'}>
              {BUTTON_CONSTANTS.SUBMIT}
            </button>
          </div>

          <h2>{LABEL_CONSTANTS.INVENTORY_INTEGRITY}</h2>
          <div className="equations">
            <div>
              <table className="verify-equation">
                <thead>
                  <tr>
                    <th>{TABLE_HEADER_CONSTANTS.ORIGINAL}</th>
                    <th />
                    <th>{TABLE_HEADER_CONSTANTS.REMOVED}</th>
                    <th />
                    <th>{TABLE_HEADER_CONSTANTS.EXPECTED}</th>
                    <th />
                    <th>{TABLE_HEADER_CONSTANTS.ACTUAL}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="value">{getOriginationTotal()}</td>
                    <td className="operation">-</td>
                    <td>{removedQuantity}</td>
                    <td className="operation">=</td>
                    <td>{getOriginationTotal() - removedQuantity}</td>
                    <td className="operation">
                      {getOriginationTotal() - removedQuantity !== getStorageTotal() && <span className="slash">/</span>}=
                    </td>
                    <td className="value">{getStorageTotal()}</td>
                  </tr>
                </tbody>
              </table>
              <div
                className={
                  getOriginationTotal() - removedQuantity !== getStorageTotal()
                    ? 'validated-input-message-error details'
                    : ' details'
                }>
                {getOriginationTotal() - removedQuantity !== getStorageTotal() && (
                  <div>
                    <AlertSVG />
                  </div>
                )}
                {VALIDATION_ALERT_CONSTANTS.RECONCILE_INVENTORY_EXPECTED}
              </div>
            </div>
            <div>
              <table className="verify-equation">
                <thead>
                  <tr>
                    <th>{TABLE_HEADER_CONSTANTS.OWNED}</th>
                    <th />
                    <th>{TABLE_HEADER_CONSTANTS.ACTUAL}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="value">{getOwnedTotal()}</td>
                    <td className="operation">{getOwnedTotal() !== getStorageTotal() && <span className="slash">/</span>}=</td>
                    <td className="value">{getStorageTotal()}</td>
                  </tr>
                </tbody>
              </table>

              <div className={getOwnedTotal() !== getStorageTotal() ? 'validated-input-message-error details' : 'details'}>
                {getOwnedTotal() !== getStorageTotal() && (
                  <div>
                    <AlertSVG />
                  </div>
                )}
                {VALIDATION_ALERT_CONSTANTS.RECONCILE_INVENTORY_OWNED}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default InventoryManagementReconcileItem;
