import { AxiosResponse } from 'axios';
import { appContextGet, appContextPost, appContextPut } from './httpCommon/appAxios.service';
import { STORAGE_ITEM_ENDPOINTS } from '../constants/apiEndpoints';
import { StorageTankOptions } from '../types/enums/storageTank.enum';
import {
  StorageCanister,
  StorageCanisterPost,
  StorageCanisterPut,
  StorageSite,
  StorageSitePost,
  StorageSitePut,
  StorageTank,
  StorageTankPost,
  StorageTankPut,
} from '../types/interfaces/storage.interfaces';
import { PagedResponse, StandardParams } from '../types/interfaces';

export const getStorageSites = (
  params?: StandardParams,
): Promise<AxiosResponse<StorageSite[] | PagedResponse<StorageSite>, any>> => {
  return appContextGet(STORAGE_ITEM_ENDPOINTS.SITES, params);
};

export const getStorageTanks = (
  params?: StandardParams,
): Promise<AxiosResponse<StorageTank[] | PagedResponse<StorageTank>, any>> => {
  return appContextGet(STORAGE_ITEM_ENDPOINTS.TANKS, params);
};

export const getStorageCanisters = (
  params?: StandardParams,
): Promise<AxiosResponse<StorageCanister[] | PagedResponse<StorageCanister>, any>> => {
  return appContextGet(STORAGE_ITEM_ENDPOINTS.CANISTERS, params);
};

export const getStorageTanksBySite = (
  siteId: string,
  storageTankOptions: StorageTankOptions,
  params?: StandardParams,
): Promise<AxiosResponse<StorageTank[], any>> => {
  let isTravelingTank: string = ';isTravelingTank eq ';
  switch (storageTankOptions) {
    case StorageTankOptions.Stationary:
      isTravelingTank += 'false';
      break;
    case StorageTankOptions.Travel:
      isTravelingTank += 'true';
      break;
    default:
      isTravelingTank = '';
      break;
  }
  const filter = ['isActive eq true;storageSiteId eq ' + siteId + isTravelingTank, params?.filter].join(';');
  return appContextGet(STORAGE_ITEM_ENDPOINTS.TANKS, { ...params, filter: filter });
};

export const getStorageCanisterByTank = (
  tankId: string,
  params?: StandardParams,
): Promise<AxiosResponse<StorageCanister[] | PagedResponse<StorageCanister>, any>> => {
  const filter = ['storageTankId eq ' + tankId, params?.filter].join(';');
  return appContextGet(STORAGE_ITEM_ENDPOINTS.CANISTERS, {
    ...params,
    filter: filter,
  });
};

export const getStorageCanisterCount = (storageCanisterId: number | string): Promise<AxiosResponse<number, any>> => {
  return appContextGet(STORAGE_ITEM_ENDPOINTS.CANISTERS_ + storageCanisterId.toString() + STORAGE_ITEM_ENDPOINTS.CANISTER_COUNT);
};

export const createStorageSite = (payload: StorageSitePost): Promise<AxiosResponse<number, any>> => {
  return appContextPost(STORAGE_ITEM_ENDPOINTS.SITES, payload);
};

export const createStorageTank = (payload: StorageTankPost): Promise<AxiosResponse<number, any>> => {
  return appContextPost(STORAGE_ITEM_ENDPOINTS.TANKS, payload);
};

export const createStorageCanister = (payload: StorageCanisterPost): Promise<AxiosResponse<number, any>> => {
  return appContextPost(STORAGE_ITEM_ENDPOINTS.CANISTERS, payload);
};

export const updateStorageSite = (
  siteId: string | number,
  payload: StorageSitePut,
): Promise<AxiosResponse<StorageCanister, any>> => {
  return appContextPut(STORAGE_ITEM_ENDPOINTS.SITES_ + siteId.toString(), payload);
};

export const updateStorageTank = (
  tankId: string | number,
  payload: StorageTankPut,
): Promise<AxiosResponse<StorageCanister, any>> => {
  return appContextPut(STORAGE_ITEM_ENDPOINTS.TANKS_ + tankId.toString(), payload);
};

export const updateStorageCanister = (
  canisterId: string | number,
  payload: StorageCanisterPut,
): Promise<AxiosResponse<StorageCanister, any>> => {
  return appContextPut(STORAGE_ITEM_ENDPOINTS.CANISTERS_ + canisterId.toString(), payload);
};
