import { FC, useEffect, useState } from 'react';
import Loader from '../loader/Loader';
import { Specimen } from '../../types/interfaces/specimen.interfaces';
import { Account } from '../../types/interfaces';
import { showToast } from '../../services/toast.service';
import { formatPositiveNumber } from '../../utils/commonUtils';
import { getSpecimenOwners } from '../../api/specimensApi';
import { getCountBySpecimen } from '../../api/inventoryApi';
import { toastMessages } from '../../constants/errorMessages';

export type AccountOwnershipQuantity = {
  account: Account;
  actualQty: number;
  oldQty: number;
};

type ReconcileAccountOwnership = {
  specimen: Specimen;
  onChange: (e: { value: AccountOwnershipQuantity[]; valid: boolean }) => void;
};

const ReconcileAccountOwnership: FC<ReconcileAccountOwnership> = ({
  specimen,
  onChange,
}: ReconcileAccountOwnership): JSX.Element => {
  const [specimens, setSpecimens] = useState<AccountOwnershipQuantity[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data: specimenAccounts } = await getSpecimenOwners(specimen.specimenId);
        const specimenOwnership = await Promise.all(
          specimenAccounts.map(async (acc: Account) => {
            const { data: quant } = await getCountBySpecimen(specimen.specimenId, { accountId: acc.accountId });
            return { account: acc, oldQty: quant, actualQty: quant };
          }),
        );
        setSpecimens(specimenOwnership);
      } catch {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setLoading(false);
      }
    };

    if (specimen) {
      fetch();
    }
  }, [specimen]);

  useEffect(() => {
    onChange({ value: specimens, valid: !loading });
  }, [specimens]);

  const getTotal = (): number => {
    return specimens.reduce((acc: number, spec: AccountOwnershipQuantity): number => acc + spec.actualQty, 0);
  };

  const onAdjustedQtyChange = (e: any, index: number) => {
    const count = formatPositiveNumber(e.target.value);
    let newSpecimens = [...specimens];
    newSpecimens[index].actualQty = count;
    setSpecimens(newSpecimens);
    onChange({ value: newSpecimens, valid: true });
  };

  return loading ? (
    <Loader loaderSize="small" addedSpace />
  ) : (
    <>
      <div className="desk-reconcile-account-ownership">
        <div className="specimen-table">
          <table>
            <thead>
              <tr>
                <th>Account/Business/Farm</th>
                <th>Owned Qty</th>
                <th>Adjusted Qty</th>
              </tr>
            </thead>
            <tbody>
              {specimens?.map((acc: AccountOwnershipQuantity, index: number) => {
                return (
                  <tr key={'canister-specimen-row:' + acc.account.accountId + '-index:' + index}>
                    <td>{acc.account.name}</td>
                    <td>{acc.oldQty}</td>
                    <td>
                      <input type="text" placeholder="0" value={acc.actualQty} onChange={e => onAdjustedQtyChange(e, index)} />
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan={2} />
                <td>
                  <div className="owned-quantity quantity">{getTotal().toString()}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/** Mobile View */}
      <div className="xs-reconcile-account-ownership">
        {specimens.length > 0 &&
          specimens &&
          specimens.map((acc, index) => {
            return (
              <div className="card max-width" key={acc?.account.name + index}>
                <div className="reconcile-account-ownership">
                  <div className="card-content-section">
                    <div className="left center">
                      <label>{`Account / Business / Farm : ${acc?.account.name}`}</label>
                    </div>
                    <div className="left center">
                      <label>{'Owned Qty: '}</label>
                      <label>{`${acc?.oldQty}`}</label>
                    </div>
                    <div className="right column center">
                      <label>{`Adjusted Qty:`}</label>
                      <input type="text" placeholder="0" value={acc.actualQty} onChange={e => onAdjustedQtyChange(e, index)} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        <div className="total-quantity">
          <label>Owned Qty: &nbsp;</label>
          {getTotal().toString()}
        </div>
      </div>
    </>
  );
};

export default ReconcileAccountOwnership;
