import { StoragePlan } from '../../types/interfaces/account.interfaces';
import { FC, useCallback, useEffect, useState } from 'react';
import { Animal } from '../../types/interfaces/animal.interfaces';
import { SpecimenType } from '../../types/interfaces/specimen.interfaces';
import { getAccountAnimals } from '../../api/animalsApi';
import { getCountByAnimal, getCountBySpecimenType, getInventoryCount } from '../../api/inventoryApi';
import { getSpecimenTypes } from '../../api/specimensTypesApi';
import { getStoragePlans } from '../../api/storagePlansApi';
import { centsToDollars } from '../../services/account.service';
import { StoragePlanUOM } from '../../types/enums/storagePlanUOM.enum';
import PieChart from './PieChart';
import GaugeChart from './GaugeChart';
import { RightArrowSvg } from '../svgs/index';
import './inventoryGraphics.scss';
import { showToast } from '../../services/toast.service';
import { toastMessages } from '../../constants/errorMessages';

type SpecimenTypeQuantity = {
  typeName?: string;
  typeId: number;
  quantity: number;
};

interface StoragePlanRange extends StoragePlan {
  lower: number;
  upper?: number;
}

const InventoryGraphics: FC<{
  accountId: number;
}> = ({ accountId }): JSX.Element => {
  const [totalInventory, setTotalInventory] = useState<number>();
  const [storagePlan, setStoragePlan] = useState<StoragePlanRange | null>(null);
  const [storagePlanOptions, setStoragePlanOptions] = useState<StoragePlanRange[]>([]);
  const [typeInventory, setTypeInventory] = useState<SpecimenTypeQuantity[]>([]);
  const [animalInventory, setAnimalInventory] = useState<{ quantity: number; animal: Animal }[]>([]);

  const [loadingTotal, setLoadingTotal] = useState<boolean>(true);
  const [loadingPlan, setLoadingPlan] = useState<boolean>(true);
  const [loadingPlanOptions, setLoadingPlanOptions] = useState<boolean>(true);
  const [loadingTypes, setLoadingTypes] = useState<boolean>(true);
  const [loadingAnimals, setLoadingAnimals] = useState<boolean>(true);
  const [slideIndex, setSlideIndex] = useState<number>(1)

  const handleGetTotalInventory = useCallback(async () => {
    if (accountId) {
      try {
        const { data: total } = await getInventoryCount({ accountId: accountId });
        setTotalInventory(total);
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setLoadingTotal(false);
      }
    }
  }, [accountId]);

  const handleGetSpecimenTypes = useCallback(async () => {
    if (accountId) {
      try {
        const { data: types } = await getSpecimenTypes();
        const inventoriesByType = await Promise.all(
          types.map(async (specimenType: SpecimenType): Promise<SpecimenTypeQuantity> => {
            const { data: quantity } = await getCountBySpecimenType(specimenType.specimenTypeId, { accountId: accountId });
            return {
              typeName: specimenType.name,
              typeId: specimenType.specimenTypeId,
              quantity: quantity,
            };
          }),
        );
        setTypeInventory(inventoriesByType);
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setLoadingTypes(false);
      }
    }
  }, [accountId]);

  const handleGetAnimals = useCallback(async () => {
    if (accountId) {
      try {
        const response = await getAccountAnimals(accountId);
        const animals = response.data as Animal[];
        const animalQuantities = await Promise.all(
          animals.map(async (animal: Animal): Promise<{ quantity: number; animal: Animal }> => {
            const { data: quantity } = await getCountByAnimal(animal.animalId!, { accountId: accountId });
            return { quantity: quantity, animal: animal };
          }),
        );

        setAnimalInventory(animalQuantities);
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setLoadingAnimals(false);
      }
    }
  }, [accountId]);

  const getInventoryGraphs = (className: string, width = 128, height = 128) => {
    return <div className={`charts ${className}`}>
      <div className={getInventoryChartClassName(className, 1)}>
        <h3>Storage Breakdown</h3>
        <div className="chart">
          <GaugeChart width={width} height={height}
            data={storagePlanOptions.map(plan => {
              return { lower: plan.lower, upper: plan.upper, label: plan.name.split(' ')[0] };
            })}
            value={totalInventory!}
            loading={loadingPlan || loadingPlanOptions || loadingTotal}
          />
        </div>
      </div>
      <div className={getInventoryChartClassName(className, 2)}>
        <h3>Animal Breakdown</h3>
        <div className="chart">
          <PieChart width={width} height={height}
            data={animalInventory.map(animal => {
              const percent = (animal.quantity / totalInventory!) * 100;
              return {
                percentage: percent,
                message: `${animal.animal.name} makes up ${percent.toFixed(1)}% of your inventory`,
              };
            })}
            loading={loadingAnimals || loadingTotal}
          />
        </div>
      </div>
      <div className={getInventoryChartClassName(className, 3)}>
        <h3>Inventory Type</h3>
        <div className="chart">
          <PieChart width={width} height={height}
            data={typeInventory.map((type: SpecimenTypeQuantity) => {
              const percent = (type.quantity / totalInventory!) * 100;
              return {
                percentage: percent,
                message: `${type.typeName} Items make up ${percent.toFixed(1)}% of your inventory`,
              };
            })}
            loading={loadingTypes || loadingTotal}
          />
        </div>
      </div>
      <button className="right-arrow mobile-view" onClick={nextSlide}><RightArrowSvg /></button>
    </div >
  }

  const getInventoryChartClassName = (className: string, index: number) => {
    return className === "mobile-view"
      ? `chart-container ${slideIndex == index ? "active-slide" : "slide"}`
      : "chart-container"
  }

  const nextSlide = () => {
    if (slideIndex !== 3) {
      setSlideIndex(slideIndex + 1)
    }
    else if (slideIndex === 3) {
      setSlideIndex(1)
    }
  }

  useEffect(() => {
    if (totalInventory !== undefined) {
      setStoragePlan(
        storagePlanOptions.reduce((acc: StoragePlanRange | null, plan: StoragePlanRange) => {
          return plan.lower <= totalInventory && (plan.upper === undefined || totalInventory <= plan.upper) ? plan : acc;
        }, null),
      );
      setLoadingPlan(false);
    }
  }, [totalInventory, storagePlanOptions]);

  const handleGetStoragePlans = useCallback(async () => {
    try {
      const { data: plans } = await getStoragePlans();
      const planRanges: StoragePlanRange[] = plans.map((plan: StoragePlan) => {
        const numbers = plan.name.split('-');
        const lower = +numbers[0].replace(/[^\d]+/, '');
        const upper = numbers.length > 1 ? +numbers[1].replace(/[^\d]+/, '') : undefined;
        const planRange = {
          ...plan,
          lower: lower,
          upper: upper,
        };
        return planRange;
      });
      setStoragePlanOptions(planRanges);
    } catch (error: any) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setLoadingPlanOptions(false);
    }
  }, [accountId]);

  useEffect(() => {
    handleGetTotalInventory();
  }, [handleGetTotalInventory]);

  useEffect(() => {
    handleGetSpecimenTypes();
  }, [handleGetSpecimenTypes]);

  useEffect(() => {
    handleGetAnimals();
  }, [handleGetAnimals]);

  useEffect(() => {
    handleGetStoragePlans();
  }, [handleGetStoragePlans]);

  return (
    <div className="inventory-graphics">
      <div className="header">
        <h2>Storage Tier</h2>
        {storagePlan && (
          <>
            <p>
              ${centsToDollars(storagePlan?.costInCents)}
              {storagePlan?.unitOfMeasurement === StoragePlanUOM.UNIT && '/Unit'}/{storagePlan?.period}
            </p>
            <p>
              {storagePlan?.period}ly Storage: {storagePlan?.name}
            </p>
          </>
        )}
      </div>
      <hr />
      {
        getInventoryGraphs("desktop-view")
      }
      {
        getInventoryGraphs("tablet-view", 120, 120)
      }
      <div className="charts-container mobile-view">
        {
          getInventoryGraphs("mobile-view", 160, 160)

        }
        <div className="dots-container">
          {Array.from({ length: 3 }).map((item, index) => (
            <div key={`dot ${index}`}
              onClick={() => setSlideIndex(index + 1)}
              className={slideIndex === index + 1 ? "dot active" : "dot"}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InventoryGraphics;
