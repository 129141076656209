import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormErrors } from '../../constants/errorMessages';
import { Validators } from '../../types/enums/validators.enum';
import { AlertSVG, ClosedEyeSVG, OpenEyeSVG, SearchSVG } from '../svgs';
import Tooltip from '../tooltip/Tooltip';
import './validatedInput.scss';

export type ValidatedState = {
  value: string;
  valid: boolean;
};

type ValidatedInputProps = {
  useId?: string;
  label: JSX.Element | string;
  validatedStateForAutoFill?: ValidatedState;
  type?: 'text' | 'email' | 'password' | 'phone' | 'email-search' | 'number';
  setValidatedState: (e: ValidatedState) => void | React.Dispatch<React.SetStateAction<ValidatedState>> | Promise<void>;
  placeholder?: string;
  validators: Validators[];
  matchString?: string;
  validate?: boolean;
  customTooltip?: JSX.Element;
  onButtonClick?: (e?: any) => void;
  disabled?: boolean;
  min?: number;
  max?: number;
  preventPaste?: boolean;
};

const ValidatedInput: FC<ValidatedInputProps> = ({
  useId,
  label,
  type = 'text',
  validatedStateForAutoFill,
  setValidatedState,
  placeholder = '',
  validators = [],
  matchString,
  validate = false,
  onButtonClick: onClick,
  disabled = false,
  customTooltip,
  min,
  max,
  preventPaste = false,
}: ValidatedInputProps) => {
  const [touched, setTouched] = useState<boolean>(false);
  const [errors, setErrors] = useState<Validators[]>(validators);
  const [visible, setVisible] = useState<boolean>(type !== 'password');
  const [value, setValue] = useState<string>(validatedStateForAutoFill?.value ?? '');

  const validateField = useCallback(
    (validateValue: string) => {
      let newErrors = validators.filter((validator: Validators) => {
        const validPattern = FormErrors[validator]?.condition;
        return !(validPattern as RegExp).test(validateValue);
      });

      if (matchString && validateValue !== matchString) {
        newErrors = [Validators.MUST_MATCH, ...newErrors];
      }
      if (type === 'number') {
        if (min !== undefined && +validateValue < min) {
          newErrors = [Validators.MIN_VALUE, ...newErrors];
        }
        if (max !== undefined && +validateValue > max) {
          newErrors = [Validators.MAX_VALUE, ...newErrors];
        }
      }
      return newErrors;
    },
    [validators, matchString, validatedStateForAutoFill],
  );

  useEffect(() => {
    if (validatedStateForAutoFill) {
      let newErrors = validateField(validatedStateForAutoFill?.value);
      setErrors(newErrors);
    } else if (matchString) {
      let newErrors = validateField(value);
      setErrors(newErrors);
    }
  }, [validatedStateForAutoFill, validateField, matchString]);

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const handlePaste = (e: any) => {
    if (preventPaste) {
      e?.preventDefault();
    }
  };

  return (
    <>
      <label className="validated-input-label" htmlFor={useId ?? ''}>
        {label}
        {customTooltip && <Tooltip>{customTooltip}</Tooltip>}

        <div className="validated-input">
          <input
            className={
              (errors?.length > 0 ? 'validated-input-error' : '') +
              (type === 'password' || type === 'email-search' ? ' has-button' : '')
            }
            type={type === 'password' ? (visible ? 'text' : 'password') : type === 'number' ? 'text' : type}
            id={useId ?? ''}
            inputMode={type === 'number' ? 'numeric' : undefined}
            aria-label={type === 'number' ? 'Number Input' : `${type} input`}
            disabled={disabled}
            onChange={e => {
              const inputValue =
                type === 'phone'
                  ? e?.target?.value?.replace(/[^\d()+-]+/, '')
                  : type === 'number'
                  ? e?.target?.value
                      .replace(/[^\d-]+/, '')
                      .replace(/(\w)[-]/, '$1')
                      .replace('--', '-')
                  : e?.target?.value;
              const newErrors = validateField(type === 'phone' ? inputValue?.replace(/[^\d]+/, '') : inputValue);
              setErrors(newErrors);
              setValue(inputValue);
              setValidatedState({
                value: inputValue,
                valid: newErrors.length === 0,
              });
            }}
            value={validatedStateForAutoFill?.value ?? value}
            min={min}
            max={max}
            onPaste={handlePaste}
            placeholder={placeholder}
            onFocus={event => event?.target?.select()}
            onBlur={() => {
              setTouched(true);
            }}
          />
          {type === 'password' && (
            <button className="validated-input-toggle-visibility button" type="button" onClick={toggleVisibility}>
              <span className="sr-only">Show or Hide Password</span>
              {visible ? <ClosedEyeSVG /> : <OpenEyeSVG />}
            </button>
          )}

          {type === 'email-search' && (
            <button className="validated-input-toggle-visibility button search-border" type="button" onClick={onClick}>
              <span className="sr-only">Search Email</span>
              <SearchSVG />
            </button>
          )}
        </div>
      </label>
      {(touched || validate) && (
        <>
          {FormErrors.map((item, index) => {
            return (
              errors.includes(item?.validator) && (
                <div key={useId + '-error-message-' + index} className="validated-input-message-error">
                  <AlertSVG /> {item?.message}
                </div>
              )
            );
          })}
        </>
      )}
    </>
  );
};

export default ValidatedInput;
