import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useAppSelector } from './redux/hooks';
import ForgotPassword from './pages/auth/ForgotPassword';
import ForgotPasswordCode from './pages/auth/ForgotPasswordCode';
import ResetPassword from './pages/auth/ResetPassword';
import ResetPasswordLink from './pages/auth/ResetPasswordLink';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import VerificationToken from './pages/account-verification/VerificationToken';
import InvitationToken from './pages/account-verification/InvitationToken';
import CreatePassword from './pages/create-password/CreatePassword';
import PageNotFound from './pages/error-pages/PageNotFound';
import ApplicationRoutes from './routes/Application.routes';
import { ROUTE_PATHS } from './constants/routePaths';

function App(): JSX.Element {
  const { token, refreshToken } = useAppSelector(state => state.auth.appContext);
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTE_PATHS.VERIFICATION_TOKEN} element={<VerificationToken />} />
        <Route path={ROUTE_PATHS.INVITATION_TOKEN} element={<InvitationToken />} />
        <Route path={ROUTE_PATHS.CREATE_PASSWORD} element={<CreatePassword />} />
        <Route path="/" element={<Navigate to={ROUTE_PATHS.APP_DASHBOARD} replace />} />
        <Route
          path={ROUTE_PATHS.SIGN_IN}
          element={token || refreshToken ? <Navigate to={ROUTE_PATHS.APP_DASHBOARD} replace /> : <SignIn />}
        />
        <Route path={ROUTE_PATHS.SIGN_UP} element={<SignUp />} />
        <Route path={ROUTE_PATHS.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTE_PATHS.FORGOT_PASSWORD_CODE} element={<ForgotPasswordCode />} />
        <Route path={ROUTE_PATHS.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={ROUTE_PATHS.RESET_TOKEN} element={<ResetPasswordLink />} />
        <Route path={ROUTE_PATHS.APP} element={<Navigate to={ROUTE_PATHS.APP_DASHBOARD} replace />} />
        <Route path={ROUTE_PATHS.APP_} element={<ApplicationRoutes />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
