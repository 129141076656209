import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { PlusSignSVG, EditSVG, DropDownSVG } from '../svgs';
import ProfileImage from '../profile-image/ProfileImage';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { UserInfo } from '../../types/interfaces/user.interfaces';
import { setActiveAccount } from '../../services/account.service';
import './accountCard.scss';

interface AccountCardProps {
  user: UserInfo;
  onClick: () => void;
  setAddAccountModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAccountNav?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAdminNav?: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenNavBar: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccountCard: FC<AccountCardProps> = ({
  user,
  onClick,
  setAddAccountModalVisible,
  setShowAccountNav,
  setShowAdminNav,
  setLoading,
  setOpen,
  setOpenNavBar,
}) => {
  const accounts = useAppSelector(state => state.user.accounts);
  const activeAccount = useAppSelector(state => state.activeAccount);
  const profileImageBlob = useAppSelector(state => state.user.profileImageBlob);
  const profileImageId = useAppSelector(state => state.user.profileImageId);
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleSwitchAccount = async (accountId?: number) => {
    if (!accountId) return;
    setLoading(true);
    await setActiveAccount(accountId);
    setDropdownVisible(false);
    navigate(ROUTE_PATHS.APP_DASHBOARD);
    setShowAccountNav && setShowAccountNav(true);
    setShowAdminNav && setShowAdminNav(false);
    setLoading(false);
    closeSideBar();
  };

  const closeSideBar = () => {
    setOpen(false);
    setOpenNavBar(false);
  };

  return (
    <div className="account-card" onClick={onClick}>
      <div className="account-item">
        <ProfileImage profileImageId={profileImageId} profileImageBlob={profileImageBlob} className="profile-image" />
        <div className="full-width">
          <Link className="button icon edit-profile" to={ROUTE_PATHS.APP_USER_PROFILE} onClick={closeSideBar}>
            <EditSVG />
            <div className="sr-only">Edit Profile</div>
          </Link>
          <h3 className={user.firstName ? '' : 'loading-content'}>
            {user.firstName} {user.lastName}
          </h3>
          <p className={user.username ? 'account-email' : 'loading-content'}>{user.username}</p>
          {accounts.length > 0 ? (
            <div
              tabIndex={1}
              className={dropdownVisible ? 'account-name switch-account reverse' : 'account-name switch-account'}
              onClick={() => setDropdownVisible(!dropdownVisible)}
              onBlur={() => setDropdownVisible(false)}>
              <p>{activeAccount.name}</p>
              <DropDownSVG />
            </div>
          ) : (
            <div className="account-name switch-account" onClick={() => setAddAccountModalVisible(true)}>
              Create Storage Account
            </div>
          )}
        </div>
        {dropdownVisible && (
          <div className="account-dropdown">
            {accounts &&
              accounts.map(({ accountId, account }) => {
                return (
                  <div
                    className="account-item centered"
                    onMouseDown={() => {
                      handleSwitchAccount(accountId);
                    }}
                    key={accountId}>
                    <h3>{account?.name}</h3>
                  </div>
                );
              })}
            <div className="account-item" onMouseDown={() => setAddAccountModalVisible(true)}>
              <PlusSignSVG />
              Create Account
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountCard;
